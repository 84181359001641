var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hasAccess
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  '\n      You do not have "Reporting - KPI Widgets permissions"\n    '
                )
              ])
            ],
            1
          )
        : _vm.dates.length === 2 && !_vm.resultsLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { "z-index": "15", top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: -90,
                                size: 100,
                                width: 15,
                                value: _vm.results.metricRateTotals,
                                color: "#98EB108A"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "52", color: "black" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.icon) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "text-h5 text--primary mb-2 ml-2" },
                          [
                            !_vm.hideCounts
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("commaNumber")(
                                          _vm.results.metricTotals
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(_vm.results.metricRateTotals) +
                                      _vm._s(_vm.appendedString) +
                                      ")\n            "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.results.metricRateTotals) +
                                      _vm._s(_vm.appendedString) +
                                      "\n            "
                                  )
                                ])
                          ]
                        ),
                        _vm.iconTwo && _vm.infoDescriptionTwo
                          ? _c(
                              "p",
                              { staticClass: "subtitle-1 ml-2 mb-1" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "z-index": "15" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      size: "24"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(_vm.iconTwo) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1146136300
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.infoDescriptionTwo))
                                    ])
                                  ]
                                ),
                                !_vm.hideCounts
                                  ? _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("commaNumber")(
                                              _vm.results.metricInitial
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm.results.metricRateInitial
                                          ) +
                                          _vm._s(_vm.appendedString) +
                                          ")\n            "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.results.metricRateInitial
                                          ) +
                                          _vm._s(_vm.appendedString) +
                                          "\n            "
                                      )
                                    ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.iconThree && _vm.infoDescriptionThree
                          ? _c(
                              "p",
                              { staticClass: "subtitle-1 ml-2 mb-1" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "z-index": "15" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      size: "24"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(_vm.iconThree) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      707237806
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.infoDescriptionThree))
                                    ])
                                  ]
                                ),
                                !_vm.hideCounts
                                  ? _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("commaNumber")(
                                              _vm.results.metricResends
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm.results.metricRateResends
                                          ) +
                                          _vm._s(_vm.appendedString) +
                                          ")\n            "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.results.metricRateResends
                                          ) +
                                          _vm._s(_vm.appendedString) +
                                          "\n            "
                                      )
                                    ])
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-row-reverse pt-1" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("viewDetails", _vm.name)
                        }
                      }
                    },
                    [_vm._v("\n        View\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.resultsLoading
        ? _c("v-skeleton-loader", { attrs: { type: "image" } })
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { "z-index": "15", top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [_vm._v("\n      No data\n    ")])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }